/* You can add global styles to this file, and also import other style files */


/* html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
} */

@import "scss/_theme";
@tailwind base;
@tailwind components;
@tailwind utilities;

// ::ng-deep .formly-field {
//     @apply flex flex-col w-full;
//   }

::ng-deep .mat-icon .success {
    --mat-icon-color: #00ac07 !important;
}
mat-icon.success {
    color: #00ac07 !important;
}
::ng-deep .mat-icon .pending {
    --mat-icon-color: #ff9640 !important;
}
mat-icon.pending {
    color: #ff9640 !important;
}