@use 'sass:map';
@use 'sass:color' as color;
@use '@angular/material' as mat;

@mixin color-variables-theme($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');
  $warn-palette: map.get($color-config, 'warn');

  :root {
    --primary-color: #{mat.get-color-from-palette($primary-palette, 500)};
    --warn-color: #{mat.get-color-from-palette($warn-palette, 500)};
  }
}
