@use "@angular/material" as mat;
@use "colors" as colors;
@include mat.core();
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$light-text: #ffffff;
$light-primary-text: $light-text;
$mat-primary: ( main: #0097ac, lighter: #b3e0e6, darker: #007a92, 200: #0097ac, // For slide toggle,
contrast: ( main: $light-primary-text, lighter: $dark-primary-text, darker: $light-primary-text, ), );
$rpl-primary: mat.define-palette($mat-primary, main, lighter, darker);
$rpl-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$rpl-warn: mat.define-palette(mat.$red-palette);
$rpl-light-theme: mat.define-light-theme( ( color: ( primary: $rpl-primary, accent: $rpl-accent, warn: $rpl-warn, ), density: 0, typography: mat.define-typography-config( $font-family: "texgyreheros", ), ));
$rpl-dark-theme: mat.define-dark-theme( ( color: ( primary: $rpl-primary, accent: $rpl-accent, warn: $rpl-warn, ), density: 0, typography: mat.define-typography-config( $font-family: "texgyreheros", ), ));
@include mat.all-component-themes($rpl-light-theme);
@include mat.strong-focus-indicators-theme($rpl-light-theme);
@include colors.color-variables-theme($rpl-light-theme);
.dark {
    @include mat.all-component-colors($rpl-dark-theme);
}

@font-face {
    font-family: texgyreheros;
    src: url(../assets/font/texgyreheros-regular.otf) format("opentype");
}

@font-face {
    font-family: texgyreheros;
    font-weight: bold;
    src: url(../assets/font/texgyreheros-bold.otf) format("opentype");
}

@font-face {
    font-family: texgyreheros;
    font-weight: bold;
    font-style: italic;
    src: url(../assets/font/texgyreheros-bolditalic.otf) format("opentype");
}

@font-face {
    font-family: texgyreheros;
    font-style: italic;
    src: url(../assets/font/texgyreheros-italic.otf) format("opentype");
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: texgyreheros, "Helvetica Neue", "Arial", sans-serif;
}